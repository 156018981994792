<template>
    <div>
        <div class="button-container-post">
            <button class="button-post-wall" @click="goToCreatePost('/posts/wall')">
                {{ i18n('My Wall') }}
            </button>
            <button class="button-post" @click="goToCreatePost('/posts/create')">
                <i class="fas fa-plus"/> {{ i18n(buttonText) }}
            </button>
        </div>
        <div class="container">
            <div class="post" v-for="(item, index) in posts" :key="index">
                <span style="display: none;">{{ item.index = index }}</span>
                <div class="m-1" v-if="hasValidateKeyObj(item, 'description')">
                    <div class="post-header">
                        <div v-if="item.avatar">
                            <img
                                class="post-author-avatar"
                                alt="avatar"
                                :src="route('core.avatars.show', { avatar: item.avatar.id })">
                        </div>
                        <div class="post-header-text">
                            <span class="post-author-name">{{ item.person_name }}</span>
                        </div>
                        <div class="post-date">
                            {{ formatDate(item.created_at) }}
                        </div>
                    </div>
                    <p class="post-city" v-if="item.location !== 'undefined'">
                        {{ item.location }}
                    </p>
                    <div v-if="item.image" class="post-image-container">
                        <img
                            class="post-image"
                            :src="'/storage/' + item.image"
                            alt="post image">
                    </div>
                    <div class="post-footer" v-if="item.description && item.description !== 'null'">
                        <p class="post-description">
                            {{ item.description }}
                        </p>
                    </div>
                    <div class="post-likes">
                        <button @click="likePost(item)">
                            <i
                                :class="[
                                    'fa',
                                    isPostLiked(item) ? 'fas text-purple-600' : 'far',
                                    'fa-heart'
                                ]"/>
                            {{ item.likes_count || 0 }}
                        </button>
                    </div>
                    <div class="comments-container">
                        <div v-for="comment in filteredComments(item)"
                            :key="comment.id" class="comment">
                            <div v-if="comment.created_by">
                                <img class="comment-avatar" alt="avatar"
                                    :src="route('core.avatars.show',
                                    { avatar: comment.created_by?.avatar?.id })">
                            </div>
                            <div class="comment-content">
                                <span class="comment-author">
                                    {{ comment.created_by.person.name }}
                                </span>
                                <template v-if="comment.body.match(/https:\/\/media.*\.gif\?/i)">
                                    <img :src="comment.body" alt="GIF">
                                </template>
                                <template v-else>
                                    <p class="comment-text">
                                        {{ comment.body }}
                                    </p>
                                </template>
                                <span class="comment-date">
                                    {{ formatDate(comment.created_at) }}
                                </span>
                            </div>
                        </div>
                        <button class="comment-show-more" v-if="shouldShowMoreButton(item)"
                                @click="toggleAllComments(item)">
                            {{ i18n( item.showAllComments ? 'Hide comments'
                            : 'See more comments') }}
                        </button>
                        <div class="wrapper">
                            <div class="comment-form">
                                <div class="textarea-wrapper">
                                <textarea class="comment-input" v-model="item.newComment"
                                        :placeholder="i18n('Write a comment...')"
                                        :id="'myTextarea_' + index"/>
                                <emoji-plcker
                                :index="index"
                                @emoji="append($event, index)"
                                @emoji-state="emojiPickerVisible"
                                :show-emoji="showEmoji[index]"
                                :id="'myButtonEmojis_' + index"/>
                                </div>
                                <gif-list
                                :index="index"
                                :show-gif="showGif[index]"
                                :gifs="gifs" @fetch-gifs="onFetch"
                                @emoji-actual="onFetch"
                                @send-gif="addComment(item, $event)"
                                @gif-state="gifPickerVisible"
                                :id="'myButtonGit_' + index"/>
                                <button class="comment-submit"
                                        @click="addComment(item)">
                                    {{ i18n('Submit') }}
                                </button>
                                <span v-if="item.commentError"
                                    class="comment-error">{{ item.commentError }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-1" v-if="hasValidateKeyObj(item, 'merit_id')">
                    <div class="post-header">
                        <div v-if="item.avatar">
                            <img
                                class="post-author-avatar"
                                alt="avatar"
                                :src="route('core.avatars.show', { avatar: item.avatar.id })">
                        </div>
                        <div class="post-header-text">
                            <span class="post-author-name">{{ item.receiver?.person.name }}</span>
                        </div>
                        <div class="post-date">
                            {{ formatDate(item.created_at) }}
                        </div>
                    </div>
                    <div v-if="item.merit.image" class="merit-image-container">
                        <img
                            class="merit-image"
                            :src="'/storage/' + item.merit.image"
                            alt="post image">
                    </div>
                    <div class="post-footer" v-if="item.merit.name && item.merit.name !== 'null'">
                        <p class="post-description">
                            <strong>{{ item.receiver?.person.name }}</strong>
                            {{ i18n('Has earned the merit') }}
                            <strong>"{{ i18n(item.merit.name) }}"</strong>
                            {{ i18n('given by') }}
                            {{ i18n(item.sender?.person.name) }}
                            {{ i18n('and got') }}
                            <strong>{{ i18n(item.points) }}</strong>
                            {{ i18n('points') }}
                        </p>
                    </div>
                    <div class="post-likes">
                        <button @click="likePost(item)">
                            <i
                                :class="[
                                    'fa',
                                    isPostLiked(item) ? 'fas text-purple-600' : 'far',
                                    'fa-heart'
                                ]"/>
                            {{ item.likes_count || 0 }}
                        </button>
                    </div>
                    <div class="comments-container">
                        <div v-for="comment in filteredComments(item)"
                            :key="comment.id" class="comment">
                            <div v-if="comment.created_by">
                                <img class="comment-avatar" alt="avatar"
                                    :src="route('core.avatars.show',
                                    { avatar: comment.created_by?.avatar?.id })">
                            </div>
                            <div class="comment-content">
                                <span class="comment-author">
                                    {{ comment.created_by.person.name }}
                                </span>
                                <template v-if="comment.body.match(/https:\/\/media.*\.gif\?/i)">
                                    <img :src="comment.body" alt="GIF">
                                </template>
                                <template v-else>
                                    <p class="comment-text">
                                        {{ comment.body }}
                                    </p>
                                </template>
                                <span class="comment-date">
                                    {{ formatDate(comment.created_at) }}
                                </span>
                            </div>
                        </div>
                        <button class="comment-show-more" v-if="shouldShowMoreButton(item)"
                                @click="toggleAllComments(item)">
                            {{ i18n( item.showAllComments ? 'Hide comments'
                            : 'See more comments') }}
                        </button>
                        <div class="wrapper">
                            <div class="comment-form">
                                <div class="textarea-wrapper">
                                <textarea class="comment-input" v-model="item.newComment"
                                        :placeholder="i18n('Write a comment...')"
                                        :id="'myTextarea_' + index"/>
                                <emoji-plcker
                                :index="index"
                                @emoji="append($event, index)"
                                @emoji-state="emojiPickerVisible"
                                :show-emoji="showEmoji[index]"
                                :id="'myButtonEmojis_' + index"/>
                                </div>
                                <gif-list
                                :index="index"
                                :show-gif="showGif[index]"
                                :gifs="gifs" @fetch-gifs="onFetch"
                                @emoji-actual="onFetch"
                                @send-gif="addComment(item, $event)"
                                @gif-state="gifPickerVisible"
                                :id="'myButtonGit_' + index"/>
                                <button class="comment-submit"
                                        @click="addComment(item)">
                                    {{ i18n('Submit') }}
                                </button>
                                <span v-if="item.commentError"
                                    class="comment-error">{{ item.commentError }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-1" v-if="hasValidateKeyObj(item, 'badge_id')">
                    <p>Insignia</p>
                    <div class="post-header">
                        <div v-if="item.avatar">
                            <img
                                class="post-author-avatar"
                                alt="avatar"
                                :src="route('core.avatars.show', { avatar: item.avatar.id })">
                        </div>
                        <div class="post-header-text">
                            <span class="post-author-name">{{ item.receiver?.person.name }}</span>
                        </div>
                        <div class="post-date">
                            {{ formatDate(item.created_at) }}
                        </div>
                    </div>
                    <div v-if="item.badge.image" class="merit-image-container">
                        <img
                            class="merit-image"
                            :src="'/storage/' + item.badge.image"
                            alt="post image">
                    </div>
                    <div class="post-footer" v-if="item.badge.name && item.badge.name !== 'null'">
                        <p class="post-description">
                            <strong>{{ item.receiver?.person.name }}</strong>
                            {{ i18n('Has earned the badge') }}
                            <strong>"{{ i18n(item.badge.name) }}"</strong>
                            {{ i18n('and got') }}
                            <strong>{{ i18n(item.points) }}
                            {{ i18n('points') }}</strong>
                        </p>
                    </div>
                    <div class="post-likes">
                        <button @click="likePost(item)">
                            <i
                                :class="[
                                    'fa',
                                    isPostLiked(item) ? 'fas text-purple-600' : 'far',
                                    'fa-heart'
                                ]"/>
                            {{ item.likes_count || 0 }}
                        </button>
                    </div>
                    <div class="comments-container">
                        <div v-for="comment in filteredComments(item)"
                            :key="comment.id" class="comment">
                            <div v-if="comment.created_by">
                                <img class="comment-avatar" alt="avatar"
                                    :src="route('core.avatars.show',
                                    { avatar: comment.created_by?.avatar?.id })">
                            </div>
                            <div class="comment-content">
                                <span class="comment-author">
                                    {{ comment.created_by.person.name }}
                                </span>
                                <template v-if="comment.body.match(/https:\/\/media.*\.gif\?/i)">
                                    <img :src="comment.body" alt="GIF">
                                </template>
                                <template v-else>
                                    <p class="comment-text">
                                        {{ comment.body }}
                                    </p>
                                </template>
                                <span class="comment-date">
                                    {{ formatDate(comment.created_at) }}
                                </span>
                            </div>
                        </div>
                        <button class="comment-show-more" v-if="shouldShowMoreButton(item)"
                                @click="toggleAllComments(item)">
                            {{ i18n( item.showAllComments ? 'Hide comments'
                            : 'See more comments') }}
                        </button>
                        <div class="wrapper">
                            <div class="comment-form">
                                <div class="textarea-wrapper">
                                <textarea class="comment-input" v-model="item.newComment"
                                        :placeholder="i18n('Write a comment...')"
                                        :id="'myTextarea_' + index"/>
                                <emoji-plcker
                                :index="index"
                                @emoji="append($event, index)"
                                @emoji-state="emojiPickerVisible"
                                :show-emoji="showEmoji[index]"
                                :id="'myButtonEmojis_' + index"/>
                                </div>
                                <gif-list
                                :index="index"
                                :show-gif="showGif[index]"
                                :gifs="gifs"
                                @fetch-gifs="onFetch"
                                @emoji-actual="onFetch"
                                @send-gif="addComment(item, $event)"
                                @gif-state="gifPickerVisible"
                                :id="'myButtonGit_' + index"/>
                                <button class="comment-submit"
                                        @click="addComment(item)">
                                    {{ i18n('Submit') }}
                                </button>
                                <span v-if="item.commentError"
                                    class="comment-error">{{ item.commentError }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { mapState } from 'vuex';
import GifList from '../gifs-emojis/GifList.vue';
import EmojiPlcker from '../gifs-emojis/EmojiPlcker.vue';

library.add(faPlus);

export default {
    name: 'UserPostsIndex',
    components: {
        GifList,
        EmojiPlcker,
    },
    inject: ['http', 'route', 'i18n', 'errorHandler'],
    data() {
        return {
            userAuth: this.userAuth,
            posts: [],
            buttonText: 'Create post',
            likes: {},
            likedPosts: [],
            comments: [],
            gifs: [],
            emojis: [],
            gifState: false,
            gifUrl: '',
            showEmoji: [],
            showGif: [],
            state: false,
            userData: {},
        };
    },
    computed: {
        filteredComments() {
            return item => {
                if (item.showAllComments) {
                    return item.comments;
                }
                return item.comments.slice(0, 2);
            };
        },
        ...mapState(['user', 'impersonating']),
        userId() {
            return this.user.id;
        },
    },
    mounted() {
        this.fetch();
        this.infoUser();
    },
    methods: {
        emojiPickerVisible(value, index) {
            this.showGif[index] = false;
            this.showEmoji[index] = value;
        },
        gifPickerVisible(value, index) {
            this.showEmoji[index] = false;
            this.showGif[index] = value;
        },
        append(emoji, id) {
            this.showEmoji[id] = true;
            this.showState = !this.showState;
            const textarea = document.getElementById(`myTextarea_${id}`);
            const startPos = textarea.selectionStart;
            const endPos = textarea.selectionEnd;

            textarea.focus();

            if (startPos !== undefined && endPos !== undefined) {
                textarea.setRangeText(emoji, startPos, endPos, 'end');
                textarea.setSelectionRange(startPos + emoji.length, startPos + emoji.length);
            } else {
                textarea.value += emoji;
            }
        },
        onFetch(res) {
            this.gifs = res;
        },
        fetch() {
            this.http
                .get(this.route('posts.userPosts'))
                .then(({ data }) => {
                    this.posts = this.sortByDate(data.posts);
                    this.userAuth = data.userAuth;

                    this.posts.forEach((post, index) => {
                        if (typeof post.likes_count !== 'number') {
                            post.likes_count = 0;
                        }

                        const likes = this.hasLikes(post.likes, this.userAuth);
                        if (likes) {
                            this.likedPosts.push(index);
                            localStorage.setItem(`post-${index}`, 'liked');
                            post.isLiked = true;
                        } else {
                            localStorage.setItem(`post-${index}`, 'disliked');
                        }
                    });
                })
                .catch(this.errorHandler);
        },
        hasLikes(postlikes, userAuth) {
            const idUserLike = postlikes.map(item => item.user_id);
            return idUserLike.includes(userAuth);
        },
        shouldShowMoreButton(item) {
            return item.comments.length > 2;
        },
        toggleAllComments(item) {
            item.showAllComments = !item.showAllComments;
        },
        sortByDate(postsLists) {
            return postsLists.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        },
        isPostLiked(post) {
            return this.likedPosts.includes(post.index);
        },
        likePost(post) {
            if (!post.likes) {
                post.likes = [];
            } else if (!Array.isArray(post.likes)) {
                post.likes = JSON.parse(post.likes);
            }

            const dataLike = {
                id: post.id,
                type: 'description',
            };

            if (this.hasValidateKeyObj(post, 'badge_id')) {
                dataLike.type = 'badge_id';
            }

            if (this.hasValidateKeyObj(post, 'merit_id')) {
                dataLike.type = 'merit_id';
            }

            const user = this.userAuth.id;
            const postLikes = post.likes.map(like => like.user_id);

            if (localStorage.getItem(`post-${post.index}`) === 'liked' || postLikes.includes(user)) {
                this.http.delete(this.route('posts.dislike', { post: post.id }), { params: dataLike })
                    .then(dataLiked => {
                        const { type } = dataLiked.data;
                        post.likes = post.likes.filter(like => like.user_id !== user);
                        post.likes_count -= 1;
                        this.likedPosts.splice(this.likedPosts.indexOf(post.index), 1);
                        post.isLiked = false;
                        const index = this.posts.findIndex(p => p.index === post.index);

                        if (type === 'disliked') {
                            localStorage.setItem(`post-${post.index}`, 'disliked');
                        } else {
                            localStorage.setItem(`post-${post.index}`, 'liked');
                        }

                        this.posts[index].likes = post.likes;
                        this.posts[index].likes_count = post.likes_count;
                    })
                    .catch(this.errorHandler);
            } else {
                this.http.post(this.route('posts.like', { post: post.id }), dataLike)
                    .then(dataLiked => {
                        const { type } = dataLiked.data;
                        post.likes.push({ user_id: user });
                        post.isLiked = true;
                        this.likedPosts.push(post.index);
                        const index = this.posts.findIndex(p => p.index === post.index);

                        if (type === 'disliked') {
                            localStorage.setItem(`post-${post.index}`, dataLiked.type);
                            post.likes_count -= 1;
                        } else {
                            localStorage.setItem(`post-${post.index}`, 'liked');
                            post.likes_count += 1;
                        }

                        this.posts[index].likes = post.likes;
                        this.posts[index].likes_count = post.likes_count;
                    })
                    .catch(this.errorHandler);
            }
        },
        goToCreatePost(route) {
            this.$router.push(route);
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('es-ES', options);
        },
        addComment(item, gif) {
            if (gif) {
                this.gifState = true;
                item.newComment = gif;
            } else {
                this.gifState = false;
                const textarea = document.getElementById(`myTextarea_${item.index}`);
                item.newComment = textarea.value.trim();
            }
            if (item.newComment.trim() === '') {
                item.commentError = 'Ingresa un comentario por favor';
                return;
            }

            const commentData = {
                post_id: item.id,
                content: item.newComment,
                type: 'description',
            };

            if (this.hasValidateKeyObj(item, 'badge_id')) {
                commentData.type = 'badge_id';
            }

            if (this.hasValidateKeyObj(item, 'merit_id')) {
                commentData.type = 'merit_id';
            }

            this.http
                .post(this.route('posts.userComments', { post: item.id }), commentData)
                .then(response => {
                    const newComment = response.data.comment;
                    item.comments.push(newComment);
                    item.newComment = '';
                    item.commentError = '';
                    const commentBox = document.querySelector(`.comment-input[data-post-id="${item.id}"]`);
                    if (commentBox) {
                        commentBox.value = '';
                    }
                    this.http
                        .get(this.route('posts.userPosts'))
                        .then(({ data }) => {
                            this.posts = this.sortByDate(data.posts);
                            this.userAuth = data.userAuth;

                            this.posts.forEach(post => {
                                if (typeof post.likes_count !== 'number') {
                                    post.likes_count = 0;
                                }

                                const likeStatus = localStorage.getItem(`post-${post.index}`);
                                if (likeStatus === 'liked') {
                                    this.likedPosts.push(post.index);
                                    post.isLiked = true;
                                }
                            });
                        })
                        .catch(this.errorHandler);
                })
                .catch(error => {
                    this.$toastr.error(error);
                });
        },
        hasValidateKeyObj(obj, keyObject) {
            return Object.prototype.hasOwnProperty.call(obj, keyObject);
        },
        infoUser() {
            this.http.get(this.route('administration.users.show', { user: this.userId }))
                .then(data => {
                    if (data.data.user.temporary_password) {
                        window.location.href = ('/addpasword');
                    }
                }).catch(e => console.error(e));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.wrapper {
  position: relative;
//   display: inline-block;
}
.h2-title {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    color: $primary;
    text-align: center;
}
.container {
    max-width: 800px;
    margin: 0 auto;
}

.post {
    margin-top: 20px;
    border-bottom: 1px solid $greyCcc;
}

.post-header {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0;
    margin: 5px;
}

.post-author-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: .75rem;
}

.post-header-text {
    display: flex;
    flex-direction: column;
}

.post-author-name {
    font-weight: bold;
    font-size: 13px;
}
.post-date {
    margin-left: .4rem;
    color: $darkDarkGrey;
    font-size: .9rem;
}

.post-city {
    margin-left: 2.5rem;
    margin-top: -1rem;
    color: $greyToneTen;
    font-size: .9rem;
}

.post-image-container {
    width: 100%;
    height: 32rem;
    display: flex;
    justify-content: center;
    padding: 2px;
}

.post-image {
    max-width: 100%;
    max-height: 100%;
}

.merit-image-container {
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    padding: 2px;
    .merit-image {
        max-width: 100%;
        max-height: 100%;
    }
}

.post-likes button {
    background-color: transparent;
    border: none;
    color: $greyToneEleven;
    cursor: pointer;
    font-size: 18px;
    margin-right: 10px;
    outline: none;
    padding: 0;
}

.post-likes button.liked {
    color: $primary;
}

.post-likes button i.fas {
    color: $primary;
}

.post-likes button i.far {
    color: $greyToneEleven;
}
.post-like-count {
    margin-left: 5px;
}

.post-footer {
    padding: 10px;
}

.post-description {
    margin: 0;
}
.button-post-wall {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 12px 16px;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease;
}

.button-post {
    background-color: $success;
    color: $secondary;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 12px 16px;
}

.button-post-wall {
    background-color: $success;
    color: $secondary;
}

.button-post i {
    margin-right: 8px;
}

.button-container-post {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button-post:hover,
.button-post-wall:hover {
    background-color: darken($primary, 10%);
}
@media screen and (max-width: 480px) {
    .button-post {
        font-size: 14px;
        padding: 10px 14px;
    }
}
@media screen and (max-width: 700px){
    .post-image-container{
        height: 20rem !important;
    }
}
/* Comments Section */
.comments-container {
    margin-top: 20px;
}

.comment {
    display: flex;
    margin-bottom: 5px;
    margin-top: 10px;
}

.comment-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: .75rem;
}

.comment-content {
    display: flex;
    flex-direction: column;
    img {
        max-height: 130px;
    }
}

.comment-author {
    font-weight: bold;
    font-size: 13px;
}

.comment-text {
    margin: 5px 0 !important;
    font-size: 14px;
}

.comment-date {
    margin-top: 5px;
    font-size: .7rem;
    color: $darkDarkGrey;
}

.comment-form {
    margin-top: .25rem;
}

.comment-input {
    width: 100%;
    padding: 8px;
    border: 1px solid $greyCcc;
    border-radius: 4px;
    resize: vertical;
}

.comment-submit {
    margin-top: 10px;
    background-color: $primary;
    color: $secondary;
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
}

.comment-submit:hover {
    background-color: darken($primary, 10%);
}

.comment-error {
    color: $red;
    font-size: 12px;
    margin-top: 5px;
}
.comment-show-more {
    color: $primary;
    font-weight: bolder;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

</style>
